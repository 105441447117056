<template>
  <div class="row">
      <div class="col-md-12 text-right mb-3">
            <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('customers.create')">
                <i class="fas fa-plus-circle"></i> Neue Addresse
            </button>
      </div>
      <div class="col-md-12">
            <div class="table-responsive">
                <table class="table table-hover table-sm">
                    <thead class="thead-light">
                        <th scope="col">Standard-Rechnung</th>
                        <th scope="col">Standard-Lieferung</th>
                        <th scope="col">Typ</th>
                        <th scope="col">Firma</th>
                        <th scope="col">Ust.-Id</th>
                        <th scope="col">DHL Kd.-Nr.</th>
                        <th scope="col">Vorname</th>
                        <th scope="col">Nachname</th>
                        <th scope="col">Straße</th>
                        <th scope="col">Nr</th>
                        <th scope="col">Filiale/Station</th>
                        <th scope="col">PLZ</th>
                        <th scope="col">Stadt</th>
                        <th scope="col">Kostenstelle</th>
                        <th scope="col">Telefon</th>
                        <th scope="col">Aktion</th>
                    </thead>
                    <tbody>
                        <tr v-for="address in addresses" :key="address.id" :class="address.type != 'address' ? 'table-warning' : ''">
                            <td>
                                <template v-if="address.id == customer.billing_address.id">
                                    <i class="fas fa-check ml-2"></i>
                                </template>
                                <input type="checkbox" class="form-check-input" @click="setBilling(address.id)" name="setBilling" id="setBilling" style="position: static; margin-left: 10px;" v-if="$auth.check('customers.edit') && !isDefaultBilling(address.id) && address.type == 'address'">
                                <!-- <b-button size="xs" class="mr-1" @click="setBilling(address.id)" variant="success" v-if="$auth.check('customers.edit') && !isDefaultBilling(address.id) && address.type == 'address'"><i class="fas fa-fw fa-file-invoice"></i></b-button> -->
                            </td>
                            <td>
                                <template v-if="address.id == customer.shipping_address.id">
                                    <i class="fas fa-check ml-2"></i>
                                </template>
                                <input type="checkbox" class="form-check-input" @click="setShipping(address.id)" name="setShipping" id="setShipping" style="position: static; margin-left: 10px;" v-if="$auth.check('customers.edit') && !isDefaultShipping(address.id)">
                                <!-- <b-button size="xs" class="mr-1" @click="setShipping(address.id)" variant="primary" v-if="$auth.check('customers.edit') && !isDefaultShipping(address.id)"><i class="fas fa-fw fa-truck"></i></b-button> -->
                            </td>
                            <td>
                                <span v-if="address.type == 'address'">Adresse</span>
                                <span v-if="address.type == 'packstation'">Packstation</span>
                                <span v-if="address.type == 'postfiliale'">Postfiliale</span>
                            </td>
                            <td>{{ address.company }}</td>
                            <td>{{ address.ustid }}</td>
                            <td>{{ address.post_customer_number }}</td>
                            <td>{{ address.first_name }}</td>
                            <td>{{ address.last_name }}</td>
                            <td>{{ address.street }}</td>
                            <td>{{ address.house_number }}</td>
                            <td>{{ address.post_number }}</td>
                            <td>{{ address.zipcode }}</td>
                            <td>{{ address.city }}</td>
                            <td>{{ address.kostenstelle }}</td>
                            <td>{{ address.phone }}</td>
                            <td>
                                <b-button size="xs" class="mr-1" @click="editModal(address)" variant="warning" v-if="$auth.check('customers.edit')"><i class="fas fa-fw fa-edit"></i></b-button>
                                <b-button size="xs" @click="deleteAddress(address.id)" variant="danger" v-if="$auth.check('customers.destroy') && !isDefault(address.id)"><i class="fas fa-fw fa-trash"></i></b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>                    
        </div>

      <b-modal id="addressModal" :title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, form.id)">
        <form ref="valueForm" @submit.stop.prevent="handleSubmit">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="company" class="control-label col-sm-2 col-form-label col-form-label-sm">Firma</label>
                        <div class="col-sm-9">
                            <select class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('type')}" v-model="form.type">
                                <option value="address">Adresse</option>
                                <option value="packstation">Packstation</option>
                                <option value="postfiliale">Postfiliale</option>
                            </select>
                            <has-error :form="form" field="type"></has-error>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">

                <div class="col-md-6">


                    <div class="form-group row" v-if="form.type == 'address'">
                        <label for="company" class="control-label col-sm-2 col-form-label col-form-label-sm">Firma</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="1" class="form-control form-control-sm" name="company" id="company" :class="{'is-invalid': form.errors.has('company')}" v-model="form.company">
                            <has-error :form="form" field="company"></has-error>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <label for="first_name" class="control-label col-sm-2 col-form-label col-form-label-sm">Vorname*</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="3" class="form-control form-control-sm" name="first_name" id="first_name" :class="{'is-invalid': form.errors.has('first_name')}" v-model="form.first_name">
                            <has-error :form="form" field="first_name"></has-error>
                        </div>
                    </div>

                    <div class="form-group row" v-if="form.type == 'address'">
                        <label for="street" class="control-label col-sm-2 col-form-label col-form-label-sm">Straße*</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="5" autocomplete="address-line1" class="form-control form-control-sm" name="street" id="street" :class="{'is-invalid': form.errors.has('street')}" v-model="form.street">
                            <has-error :form="form" field="street"></has-error>
                        </div>
                    </div>

                    <div class="form-group row" v-if="form.type != 'address'">
                        <label for="post_customer_number" class="control-label col-sm-2 col-form-label col-form-label-sm">DHL Kd.-Nr.*</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="5" autocomplete="address-line1" class="form-control form-control-sm" name="post_customer_number" id="post_customer_number" :class="{'is-invalid': form.errors.has('post_customer_number')}" v-model="form.post_customer_number">
                            <has-error :form="form" field="post_customer_number"></has-error>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="zipcode" class="control-label col-sm-2 col-form-label col-form-label-sm">PLZ*</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="7" autocomplete="postal-code" class="form-control form-control-sm" name="zipcode" id="zipcode" :class="{'is-invalid': form.errors.has('zipcode')}" v-model="form.zipcode">
                            <has-error :form="form" field="zipcode"></has-error>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="phone" class="control-label col-sm-2 col-form-label col-form-label-sm">Telefon</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="9" class="form-control form-control-sm" name="phone" id="phone" :class="{'is-invalid': form.errors.has('phone')}" v-model="form.phone">
                            <has-error :form="form" field="phone"></has-error>
                        </div>
                    </div>
                    <div class="form-group row" v-if="form.type == 'address'">
                        <label for="kostenstelle" class="control-label col-sm-2 col-form-label col-form-label-sm">Kostenstelle</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="9" class="form-control form-control-sm" name="kostenstelle" id="kostenstelle" :class="{'is-invalid': form.errors.has('kostenstelle')}" v-model="form.kostenstelle">
                            <has-error :form="form" field="kostenstelle"></has-error>
                        </div>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group row" v-if="form.type == 'address'">
                        <label for="department" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Abteilung</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="2" name="department" id="department" class="form-control form-control-sm" v-model="form.department" :class="{'is-invalid': form.errors.has('department')}">
                            <has-error :form="form" field="department"></has-error>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="last_name" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Nachname*</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="4" name="last_name" id="last_name" class="form-control form-control-sm" v-model="form.last_name" :class="{'is-invalid': form.errors.has('last_name')}">
                            <has-error :form="form" field="last_name"></has-error>
                        </div>
                    </div>

                    <div class="form-group row" v-if="form.type == 'address'">
                        <label for="house_number" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Hausnummer*</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="6" name="house_number" id="house_number" class="form-control form-control-sm" v-model="form.house_number" :class="{'is-invalid': form.errors.has('house_number')}">
                            <has-error :form="form" field="house_number"></has-error>
                        </div>
                    </div>

                    <div class="form-group row" v-if="form.type != 'address'">
                        <label for="post_number" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Station ID*</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="5" autocomplete="address-line1" class="form-control form-control-sm" name="post_number" id="post_number" :class="{'is-invalid': form.errors.has('post_number')}" v-model="form.post_number">
                            <has-error :form="form" field="post_number"></has-error>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="city" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Stadt*</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="8" autocomplete="address-line2" name="city" id="city" class="form-control form-control-sm" v-model="form.city" :class="{'is-invalid': form.errors.has('city')}">
                            <has-error :form="form" field="city"></has-error>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="ustid" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Ust.-ID</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="10" name="ustid" id="ustid" class="form-control form-control-sm" v-model="form.ustid" :class="{'is-invalid': form.errors.has('ustid')}">
                            <has-error :form="form" field="ustid"></has-error>
                        </div>
                    </div>
                </div>
            </div>

            
        </form>
    </b-modal>
  </div>
</template>

<script>
export default {
    name: "CustomerAddresses",
    props: [
        'addresses',
        'customer',
    ],
    data(){
        return{
            form: new window.Form({
                id: "",
                customer_id: "",
                first_name: "",
                last_name: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                company: "",
                department: "",
                phone: "",
                ustid: "",
                type: "address",
                post_number: "",
                post_customer_number: "",
                kostenstelle: null,
            }),
            editMode: false,
            modalTitle: '',
        }
    },

    methods:{
        deleteAddress(id){
            this.$swal({
                title: "Möchtest du die Adresse wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/addresses/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Adresse erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.$parent.loadCustomer();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        setShipping(id){
            this.$Progress.start();
            this.axios
                .post("/customers/default/shipping", {customer_id: this.customer.id, address_id: id})
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Standard Lieferadresse wurde geändert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.$parent.loadCustomer();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        setBilling(id){
            this.$Progress.start();
            this.axios
                .post("/customers/default/billing", {customer_id: this.customer.id, address_id: id})
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Standard Rechnungsadresse wurde geändert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.$parent.loadCustomer();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        isDefault(id){
            if(id == this.customer.billing_address.id)
            {
                return true;
            }
            if(id == this.customer.shipping_address.id)
            {
                return true;
            }
            return false;
        },

        isDefaultShipping(id)
        {
            if(id == this.customer.shipping_address.id)
            {
                return true;
            }
            return false;
        },

        isDefaultBilling(id){
            if(id == this.customer.billing_address.id)
            {
                return true;
            }
            return false;
        },

        createModal(){
            this.editMode = false;
            this.modalTitle = "Neue Adresse anlegen";
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("addressModal");
        },

        editModal(Address) {
            this.editMode = true;
            this.modalTitle = "Adresse editieren";
            this.form.clear();
            this.form.reset();
            this.form.fill(Address);
            this.$bvModal.show("addressModal");

        },

        handleOk(bvModalEvt, id){
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id){
            if(this.editMode == true)
            {
                //Edit User
                this.editAddress(id);
            }
            else
            {
                //Create User
                this.createAddress();
            }
        },

        editAddress(id)
        {
            this.$Progress.start();
            this.form
                .put("/addresses/" + id)
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Addresse erfolgreich geändert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$bvModal.hide("addressModal");
                    this.$Progress.finish();
                    this.$parent.loadCustomer();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        createAddress()
        {
            this.$Progress.start();
            this.form.customer_id = this.customer.id;
            this.form
                .post("/addresses")
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Addresse erfolgreich hinzugefügt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$bvModal.hide("addressModal");
                    this.$Progress.finish();
                    this.$parent.loadCustomer();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }


    }

}
</script>

<style>

</style>